
import { defineComponent, ref } from "vue"
import { Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

export default defineComponent({
    name: "addApi",
    components: {
        Field,
        Form
    },
    props: {
    },
    data() {



        return {
            apiGroupList: [] as any,
            showTokenError: false,
            showErrorApi: false,
            errors: [],
            orgList: [] as any,
            methodList: [{ id: "POST" }, { id: "PUT" }, { id: "GET" }, { id: "PATCH" }, { id: "DELETE" }, { id: "OPTION" }],
            otherErrors: {
                postmanCollection: ""
            },
            gatewayList: ["Insurance", "Wealth Gateway", "Platform"]
        }

    },
    setup(props) {
        const addApiModalRef = ref<null | HTMLElement>(null);
        const formData = ref({
            apiName: "",
            apiId: "",
            apiGroupName: "",
            organizationCode: "",
            apiGroupId: "",
            apiPath: "",
            method: "POST",
            apiGroupDescription: "",
            apiGroupDocUrl: "",
            postmanCollection: "" as any,
            version: "",
            gatewayName: "",
            mockedRequest: "",
            successRespStatusCode: "",
            expectedMockedResponse: "",
            mockedRequestContentType: "",
            mockedResponseContentType: "",
            failureRespStatusCode: "404, 502, 503, 504"
        });
        const formValidation = Yup.object().shape({
            apiName: Yup.string().label("Endpoint Name").required("Please add Endpoint Name"),
            apiId: Yup.string().label("Endpoint ID").required("Please Add Endpoint ID"),
            apiGroupName: Yup.string().label("API Group Name").required("Please Add API Group Name"),
            gatewayName: Yup.string().label("Gateway Name").required("Please Gateway Name"),
            // postmanCollection: Yup.string().test('is-json', 'Invalid JSON', (value) => {
            //     if (typeof value !== 'string') return false; // Ensure value is a string
            //     try {
            //         JSON.parse(value);
            //         return true;
            //     } catch (error) {
            //         return false;
            //     }
            // })
            apiGroupDescription: Yup.string().label("Description").required("Please Add API Description"),
            failureRespStatusCode: Yup.string().label("Description").required("Please Add Failure Response Status Code")
        });

        return {
            addApiModalRef,
            formData,
            formValidation
        }

    },
    methods: {
        //change Api Group
        changeApiGroup(){
            const apiDetaills = this.apiGroupList.find((item)=>item.id === this.formData.apiGroupId);
            this.formData.apiGroupDescription='';
            this.formData.apiGroupName = '';
            this.formData.apiGroupDescription = apiDetaills.description;
            this.formData.apiGroupName = apiDetaills.name;
        },
        //change org
        orgChange()
        {
            ApiService.get(`/master?organizationId=${this.formData.organizationCode}`)
        .then((data) => {
          const { APIGroup } = data.data.data
          this.apiGroupList = APIGroup;
          this.formData.apiGroupId = this.apiGroupList[0].id;
          this.formData.apiGroupDescription =this.apiGroupList[0].description ? this.apiGroupList[0].description : "";
          this.formData.apiGroupName  = this.apiGroupList[0].name ? this.apiGroupList[0].name : "";
        })
        .catch((error) => {
          console.log("Error", error)
        })
        },
        callUpdate(apiGroupList, OrgList) {
            this.apiGroupList = apiGroupList;
            this.orgList = OrgList
            this.formData.organizationCode = '1SB';
            this.formData.apiGroupId = 'Annuities';
            this.changeApiGroup();
        },
        //removeField on close Module
        removeFields() {
            this.$emit("changeAPI", "false");
            this.formData.apiName = "",
                this.formData.apiId = "",
                this.formData.apiGroupName = "",
                this.formData.apiGroupId = this.apiGroupList ? this.apiGroupList[0].id : "",
                this.formData.organizationCode = this.orgList ? this.orgList[0].id : '',
                this.formData.apiPath = "",
                this.formData.method = "POST",
                this.formData.apiGroupDocUrl = "",
                this.formData.apiGroupDescription = "",
                this.formData.postmanCollection = "" as any,
                this.formData.version = "",
                this.formData.gatewayName = "",
                this.formData.mockedRequest = "",
                this.formData.successRespStatusCode = "",
                this.formData.expectedMockedResponse = "",
                this.formData.mockedRequestContentType = "",
                this.formData.mockedResponseContentType = "",
                this.formData.failureRespStatusCode = "404, 502, 503, 504"

        },
        //remote method search
        remoteMethod(query: string) {
            const test = query;
        },

        validation_fields(){
            try{
                if(!this.formData.postmanCollection){
                    return false
                }
                if (typeof(this.formData.postmanCollection) === "object"){
                    JSON.stringify(this.formData.postmanCollection)
                }
                if((typeof(this.formData.postmanCollection) === "string")){
                    JSON.parse(this.formData.postmanCollection)
                }
                return false
            }catch (error){
                this.otherErrors.postmanCollection = "Invalid JSON field Postman Collection."
                return true
            }
        },
        submitForm() {
            this.otherErrors.postmanCollection = "";
            this.formData.postmanCollection = this.formData.postmanCollection ? this.formData.postmanCollection: "{}";
            let error = this.validation_fields()
            if (this.errors.length === 0 && !this.showTokenError && !error &&
                !this.showErrorApi) {
                if(typeof(this.formData.postmanCollection) === "string"){
                    this.formData.postmanCollection = JSON.parse(this.formData.postmanCollection)
                }
                this.$emit("changeAPI", "true");
                ApiService.post("/organizations/1SB/apis", this.formData as any)
                    .then((data) => {

                        this.removeFields();
                        hideModal(this.addApiModalRef);

                    ElNotification({
                        title: 'Success',
                        message: 'New API End Point added!',
                        type: 'success',
                        duration: 2000,
                        position: 'top-right'
                    })


                })
                .catch((error) => {
                    console.log(error)
                    ElNotification({
                        title: 'Error',
                        message: JSON.stringify(error.response.data['errors'][0]['errorMessage']),
                        type: 'error',
                        duration: 2000,
                        position: 'top-right'
                    })
                });


            }
        },
    },
});
